@use "./variables.scss";

.standalone-label{
    color: variables.$secondary-color;
    display: block;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    &>span{
        color: variables.$secondary-color;
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
    }
}
.standalone-error-field-display {
    display: block;
    font-size: 14px;
    color: variables.$error-red;
    line-height: normal;
    padding-top: 2px;
}
.field {
    font-size: 14px;
    .label{
        color: variables.$primary-color;
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        span{
            font-weight: 300;
            color: variables.$primary-color;
            margin-left: 3px;
            font-size: 12px;
        }
    }
    .after-string{
        margin-left: 12px;
        font-size: 14px;
        white-space: nowrap;
    }
    .error-field{
        border: 1px solid variables.$error-red !important;
        .ql-toolbar{
            border-top: 1px solid variables.$error-red !important;
            border-left: 1px solid variables.$error-red !important;
            border-right: 1px solid variables.$error-red !important;
        }
        .ql-container{
            border-bottom: 1px solid variables.$error-red !important;
            border-left: 1px solid variables.$error-red !important;
            border-right: 1px solid variables.$error-red !important;
        }
    }
    .invalid-field{
        background-color: #ffeded;
    }
    .error-field-display {
        display: block;
        font-size: 14px;
        color: variables.$error-red;
        line-height: normal;
        padding-top: 2px;
    }
    .error-item{
        display: block;
        color: variables.$error-red;
    }
    input:disabled, select:disabled{
        background-color: #f5f5f5 !important;
        cursor: not-allowed;
        opacity: 0.5;
    }
    &.field-select{
        .options-inner{
            overflow-y: auto;
            max-height: 452px;
            max-height: 400px;
        }
        .options, .dropdown-menu{
            position: absolute;
            top: 100%;
            background-color: transparent;
            box-shadow: 0px 2px 8px 0px rgba(53, 84, 119, 0.3);
            z-index: 9999;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            .option, .dropdown-item{
                &:last-of-type{
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
        .dropdown-menu{
            padding: 0;
            margin: 0;
            border: none;
            border-radius: 0;
            transform: none !important;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            width: 100%;
            left: 0;
            top: 0;
            border-radius: 4px;
            overflow: hidden;
            padding-top: 48px;
            max-height: 500px;
            overflow-y: auto;
            font-size: 14px;
            padding-top: 0;
            top: 100% !important;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            *{font-size: 14px;}
            .dropdown-item{
                background-color: #fff;
                color: variables.$text-color;
                min-height: 40px;
                padding: 12px 8px;
                white-space: nowrap;
                border-left: 1px solid #E5E5E5;
                border-right: 1px solid #E5E5E5;
                cursor: pointer;
                border-top: 1px solid #E5E5E5;
                &:hover{
                    color: white;
                    background-color: variables.$primary-color;
                    *{color: white;}
                }
                &.active{
                    color: white;
                    background-color: variables.$primary-color;
                    *{color: white;}
                }
                &:first-of-type{
                    border-top: none;
                }
                &:last-of-type{
                    border-bottom: 1px solid #E5E5E5;
                }
                ngb-highlight{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    .ngb-highlight{
                        font-weight: 400 !important;
                        text-decoration: underline !important;
                    }
                }
            }
        }
    }
}
.field-checkbox-wrap{
    display: block;
    background-color: #fff;
    transition: .1s ease;
    cursor: pointer;
    width: 100%;
    // padding: 4px 8px;
    color: variables.$text-color;
    min-height: 40px;
    line-height: 20px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    &.active{
        .fake-checkbox{
            border-color: variables.$secondary-color !important;
            background: variables.$secondary-color !important;
            &::after{
                opacity: 1;
            }
        }
    }
    .fake-checkbox{
        flex-shrink: 0;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 8px;
        border-radius: 2px;
        border: 1px solid variables.$gray;
        position: relative;
        transition: 0.1s ease;
        &::after{
            opacity: 0;
            content: "";
            position: absolute;
            left: 3px;
            top: 0px;
            display: block;
            transition: 0.1s ease;
            width: 6px;
            height: 8px;
            border: solid white;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg) translateY(0.5px) translateX(1px);
        }
    }
}

// ng bootstrap datepicker

ngb-datepicker{
    box-shadow: 0 3px 12px rgb(144 144 144 / 25%) !important;
    border: none !important;
}
.ngb-dp-navigation-select{
    .form-select{
        &:first-of-type{
                border: 0 !important;
                background: none !important;
                outline: none !important;
                text-align: right !important;
                font-weight: 500 !important;
                color: #000 !important;
                box-shadow: none !important;
                padding-right: 2px !important;
        }
        &:last-of-type{
                border: 0 !important;
                background: none !important;
                outline: none !important;
                text-align: left !important;
                font-weight: 500 !important;
                box-shadow: none !important;
                color: #000 !important;
                padding-left: 2px !important;
        }
    }
}
.ngb-dp-arrow{
    &:first-of-type{
        .ngb-dp-navigation-chevron{
            width: 7px !important;
            height: 7px !important;
            border-width: 1px 1px 0 0 !important;
            display: block !important;
        }
    }
    &:last-of-type{
        .ngb-dp-navigation-chevron{
            width: 7px !important;
            height: 7px !important;
            border-width: 1px 1px 0 0 !important;
            display: block !important;
        }
    }
}
.ngb-dp-header{
    background-color: transparent !important;
}
.ngb-dp-weekdays{
    background-color: transparent !important;
    border-bottom: none !important;
}
.bg-primary{
    background-color: variables.$primary-color !important;
}
.ngb-dp-weekday{
    color: #000 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
}

ngb-datepicker-month{
    font-size: 14px;
}
ngb-dp-arrow-btn{
    color: #000;
}