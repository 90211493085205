@use "./variables.scss";

// component
.button-component{
    white-space: nowrap;
    transition: 0.1s ease;
    box-shadow: none !important;
    outline: none !important;
    padding: 10px 16px;
    border: none;
    // display: flex;
    display: inline-flex;
    justify-content: center;    // login button text stond niet in midden
    align-items: center;
    font-size: variables.$fontsize-s;
    font-weight: 400;
    line-height: 20px;
    border-radius: 4px;
    letter-spacing: 1px;
    user-select: none;
    text-decoration: none !important;
    *{text-decoration: none;}
    &.password-button{
        padding: 0px 8px;
    }
    // types
    &.primary-default{
        background: variables.$primary-color;
        *{color: #fff;}
        &:hover{
            filter: brightness(1.05);
        }
    }
    &.green-default{
        background: #49988E;
        *{color: #fff;}
        &:hover{
            filter: brightness(1.05);
        }
    }
    &.darkblue-default{
        background: #394656;
        *{color: #fff;}
        &:hover{
            filter: brightness(1.05);
        }
    }
    &.blue-default{
        background: variables.$link-blue;
        *{color: #fff;}
        &:hover{
            filter: brightness(1.05);
        }
    }
    &.lightblue-default{
        background: rgba(41, 103, 195, 0.15);
        *{color: variables.$link-blue}
    }
    &.primary-light{
        background: variables.$primary-color-lightest;
        *{color: variables.$primary-color-darker-alt;}
        .inline-spinner-container{
            .sp-circle {
                border: 2px rgba(variables.$primary-color, 0.25) solid !important;
                border-top: 2px rgba(variables.$primary-color, 1) solid !important;
            }
        }
    }
    &.darkblue-light{
        background: #E0E9EE;
        *{color: #394656;}
    }
    &.cancel-sm{
        font-size: 12px;
        line-height: 20px;
        padding: 6px 12px;
        *{color: #394656;}
        background: #EAF0F3;
        .button-pre-wrap{
            width: 25px !important;
        }
    }
    &.confirm-sm{
        font-size: 12px;
        line-height: 20px;
        padding: 6px 12px;
        background: #49988E;
        *{color: #fff;}
        &:hover{
            filter: brightness(1.05);
        }
        .button-pre-wrap{
            width: 25px !important;
        }
    }
    // &.default-xsm{
    //     background: variables.$primary-color;
    //     line-height: 24px;
    //     font-size: variables.$fontsize-s;
    //     padding: 6px 12px;
    //     *{color: #fff;}
    //     &:hover{
    //         background: variables.$primary-color;
    //         filter: brightness(1.1);
    //     }
    //     &.default-xsm--secondary{
    //         background: variables.$secondary-color;
    //         &:hover{
    //             background: variables.$secondary-color;
    //             filter: brightness(1.1);
    //         }
    //     }
    //     &.default-xsm--dark{
    //         background: #394756;
    //         &:hover{
    //             background: #394756;
    //             filter: brightness(1.2);
    //         }
    //     }
    // }

    // icon
    i.im-icon{
        margin: 0;
        font-size: 16px;
    }
    // spinner
    .inline-spinner-container{
        transform: none !important;
        .sp {
            width: 16px !important;
            height: 16px !important;
            clear: both !important;
        }
        .sp-circle {
            border: 2px rgba(#fff, 0.25) solid !important;
            border-top: 2px rgba(#fff, 1) solid !important;
        }
    }
    // wrap icon spinner
    .button-pre-wrap{
        width: 26px;
        display: inline-flex;
    }
    // states
    &.button--disabled{
        transition: none !important;
        pointer-events: none;
        opacity: .6;
    }
    &.button--submitting{
        transition: none !important;
        pointer-events: none;
        opacity: .8;
    }
}

// general
.button{
    user-select: none;
    white-space: nowrap;
    transition: 0.1s ease;
    color: #fff;
    box-shadow: none !important;
    outline: none !important;
    padding: 10px 18px 10px 18px;
    background: variables.$primary-color;
    border: none;
    display: flex;
    align-items: center;
    font-size: variables.$fontsize-s;
    font-weight: 400;
    line-height: 20px;
    border-radius: 4px;
    letter-spacing: 1px;
    &:hover{
        background: variables.$primary-color;
        filter: brightness(1.1);
    }
    &.default{
        background: variables.$primary-color;
        &:hover{
            background: variables.$primary-color;
            filter: brightness(1.1);
        }
    }
    &.primary-default--sm{
        // font-size: 12px;
        line-height: 22px;
        padding: 6px 14px;
        color: #fff;
        background: variables.$primary-color;
        &:hover{
            filter: brightness(1.05);
        }
    }
    &.primary-light--sm{
        // font-size: 12px;
        line-height: 22px;
        padding: 6px 14px;
        color: variables.$primary-color-darker-alt;
        background: variables.$primary-color-lightest;
        &:hover{
            filter: brightness(1);
        }
    }
    &.default-sm{
        background: variables.$primary-color;
        line-height: 20px;
        font-size: variables.$fontsize-s;
        text-transform: none;
        padding: 8px 18px;
        &:hover{
            background: variables.$primary-color;
            filter: brightness(1.1);
        }
    }
    &.default-sm--light{
        background: transparent;
        border: 2px solid variables.$primary-color;
        color: variables.$primary-color-darker-alt;
        line-height: 20px;
        font-size: variables.$fontsize-s;
        text-transform: none;
        padding: 6px 18px;
        &:hover{
        //     background: variables.$primary-color;
        //     color: white;
            filter: brightness(1.1);
        }
    }
    // &.default-xsm{
    //     background: variables.$primary-color;
    //     line-height: 24px;
    //     font-size: variables.$fontsize-s;
    //     text-transform: none;
    //     padding: 6px 12px;
    //     &:hover{
    //         background: variables.$primary-color;
    //         filter: brightness(1.1);
    //     }
    //     &.secondary{
    //         background: variables.$secondary-color;
    //         &:hover{
    //             background: variables.$secondary-color;
    //             filter: brightness(1.1);
    //         }
    //     }
    //     &.dark{
    //         background: #394756;
    //         &:hover{
    //             background: #394756;
    //             filter: brightness(1.2);
    //         }
    //     }
    // }
    // &.secondary{
    //     background: variables.$secondary-color;
    //     &:hover{
    //         background: variables.$secondary-color;
    //         filter: brightness(1.1);
    //     }
    // }
    // &.dark{
    //     background: #394756;
    //     &:hover{
    //         background: #394756;
    //         filter: brightness(1.2);
    //     }
    // }
    &.tertiary{
        background: variables.$primary-color-lightest;
        color: variables.$primary-color-darker-alt;
        *{color: variables.$primary-color-darker-alt;}
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        padding: 10px 16px;
        border-radius: 4px;
        letter-spacing: 1px;
        &.delete-red{
            background-color: #fee9e9;
            color: variables.$error-red-light !important;
            *{color: variables.$error-red-light !important;}
        }
        .im-icon{
            transform: translateY(-.5px);
            font-size: 12px;
        }
        &:hover{
            filter: brightness(1);
        }
    }
    &.default--light{
        background: #fff;
        color: variables.$secondary-color;
        border: 2px solid variables.$secondary-color;
        padding: 8px 13px;
        &:hover{
            background: variables.$secondary-color;
            color: #fff;
            filter: none;
        }
    }
    &.delete{
        background: variables.$error-red;
        &:hover{
            background: variables.$error-red;
            filter: brightness(1.1);
        }
    }
    &:disabled,
    &.disabled{
        pointer-events: none;
        opacity: .7;
    }
    .im-icon{
        font-size: variables.$fontsize-xxs;
        margin-right: 7px;
        transform: translateY(-.5px);
        &.im-icon-plus-round{
            font-size: 16px;
            margin-right: 10px;
            transform: scale(1.2) translateY(-.5px);
        }
    }
    &.cancel{
        padding: 8px 11px;
        background: #fff;
        color: #050505;
        font-size: variables.$fontsize-s;
        border: 1px solid #050505;
    }
    &.confirm{
        padding: 8px 11px;
        background: variables.$error-red-light;
        color: #fff;
        font-size: variables.$fontsize-s;
        border: 1px solid variables.$error-red-light;
    }
}
.button-collapse{
    z-index: 2;
    position: absolute;
    background-color: #F9F9FA;
    color: variables.$secondary-color;
    color: #4F5563;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31px;
    height: 31px;
    padding: 0;
    border-radius: 99px;
    border: 1px solid #E5E5E5;
    transition: variables.$navTransition;
    transition: .1s ease;
    &>i{
        margin: 0 !important;
        font-size: 12px !important;
        &.im-icon-chev-left{
            transform: translateX(-1px);
        }
        &.im-icon-chev-right{
            transform: translateX(1px);
        }
    }
    &:hover{
        background-color: rgb(255, 255, 255);
    }
}
.format-link{
    color: #1098F7;
    color: variables.$link-blue;
    text-decoration: underline;
    &:hover{
        color: #1098F7;
        color: variables.$link-blue;
        text-decoration: underline;
    }
}
.button-link, .link{
    white-space: nowrap;
    color: variables.$link-blue !important;
    font-size: variables.$fontsize-s;
    display: flex;
    display: inline-flex;
    align-items: center;
    &:hover{
        color: variables.$link-blue !important;
        *{
            color: variables.$link-blue !important;
        }
    }
    &:hover span{
        text-decoration: underline;
    }
    &:disabled,
    &.disabled{
        pointer-events: none;
        opacity: .7;
    }
    &.cancel{
        padding: 8px 11px;
    }
    &.disabled--visually{
        opacity: .5;
        cursor: not-allowed;
    }
}
.remove-button{
    font-size: 12px;
    white-space: nowrap;
    transition: 0.1s ease;
    color: #E54852;
    box-shadow: none !important;
    outline: none !important;
    padding: 0 8px;
    background: #FCEDEE;
    border: none;
    display: flex;
    align-items: center;
    font-weight: 400;
    line-height: 24px;
    border-radius: 4px;
    flex-shrink: 0;
    height: 24px;
    &:hover{
        opacity: .85;
    }
}
.button-link.delete{
    color: variables.$error-red-light !important;
    font-size: variables.$fontsize-s;
}
button .im-icon{
    font-size: variables.$fontsize-xxs;
    margin-right: 5px;
    text-decoration: none !important;
}
.export-btn .im-icon{
    font-size: variables.$fontsize-xxs;
    margin-right: 5px;
}
.export-btn {
    text-decoration: none !important;
}
.export-btn:hover span {
    text-decoration: underline !important;
}
.button-row-sep{
    background: #dadddb;
    width: 1px;
    align-self: stretch;
}