@use "./variables.scss";

.popover{
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    max-width: 375px !important;
    .popover-arrow{
        display: none !important;
    }
    .popover-body{
        padding: 0;
        border-radius: 2px;
        box-shadow: 0px 8px 12px 0px #0000001F;
        border: 1px solid #EAF0F3;
        background-color: #fff;
    }
    ul {
        margin-bottom: 0;
        padding: 0;
        li{
            line-height: 21px;
            white-space: nowrap;
            font-size: variables.$fontsize-xs;
            color: #4F5563;
            cursor: pointer;
            padding: 8px 16px;
            line-height: 24px;
            border-bottom: 1px solid #EAF0F3;
            text-align: left;
            &:hover{
                background-color: #F9F9FA;
            }
            &:last-of-type{
                border-bottom: none;
            }
            &.title{
                font-size: inherit;
                color: variables.$text-color;
                border-bottom: none;
                padding-top: 18px;
                padding-bottom: 8px;
                cursor: inherit;
            }
            &.title:hover{
                background: #fff;
            }
        }
        .im-icon{
            font-size: 12px;
            margin-right: 8px;
        }
    }
}

.popover--profile{
    min-width: 260px;
    // border-top: 2px solid variables.$primary-color !important;
    // border-top-left-radius: 2px !important;
    // border-top-right-radius: 2px !important;
    .popover-body{
        border: none;
        border: 1px solid #F1F1F1;
        border-radius: 4px;
    }
    // &::after{
    //     content: '';
    //     border-style: solid;
    //     border-width: 0 4px 4px 4px;
    //     border-color: transparent transparent $primary-color transparent;
    //     position: absolute;
    //     bottom: calc(100% + 2px);
    //     right: 16px;
    // }
    .head{
        background: #F5F5F5;
    }
    .options{
        ul{
            li{
                border: none;
                background: none !important;
                padding: 0;
                *{transition: none !important;}
                &.logout{
                    a{
                        color: #4F5563;
                    }
                }
                &:last-of-type div{
                    border-bottom: none;
                }
                a{
                    &:hover{
                        background: #F9F9FA;
                    }
                    div{
                        border-bottom: 1px solid #eaeaea;
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }
                    width: 100%;
                    transition: .1s ease;
                    font-size: 14px;
                    * {
                        font-size: 16px;
                    }
                    line-height: 24px;
                    color: variables.$text-color;
                    padding-left: 18px;
                    padding-right: 18px;
                    text-decoration: none;
                    span{
                        margin: 0;
                        min-width: 27px;
                        display: inline-flex;
                        align-items: center;
                        i{
                            transition: .2s ease;
                            font-size: 16px;
                            margin-right: 0;
                            transform: translateY(-1px);
                        }
                    }
                }
            }
        }
    }
}

.popover--confirm-action{
    max-width: 400px;
    left: 16px !important;
    .popover-arrow{
        display: block !important;
        transform: translatex(-100%) !important;
        left: calc(100% - 32px) !important;
        top: -8px !important;
        &::after{
            bottom: 2px !important;
            border-top-color: #F9F9FA !important;
        }
        &::before{
            bottom: 1px !important;
            border-top-color: #EAF0F3 !important;
        }
    }
    .popover-body{
        border-radius: 4px;
    }
}
// <div class="delete-inline-button" [ngbPopover]="popContent" popoverClass="popover--confirm-action" placement="top-right" (shown)="false" (hidden)="false">
//     <i class="im-icon im-icon-trash"></i>
// </div>
// <ng-template #popContent><app-confirm-action [open]="true" [confirmTitle]="''" [confirmDescr]="''" (confirmed)="deleteContact($i); formChange(); contactsChange();" (cancelled)="false"></app-confirm-action></ng-template>


.popover--track-dossier{
    min-width: 405px;
    .popover-body{
        border: none;
        border-radius: 4px;
        padding: 16px;
        padding-bottom: 24px;
        border: 1px solid #F5F5F5;
        box-shadow: 0px 12px 16px 0px #0000001F;
        // content
        .title{
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 16px;
        }
        .textarea-wrap{
            margin-bottom: 32px;
        }
        .foot{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 0;
            justify-content: right;
            .button{
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 1px;
                min-width: 160px;
                background-color: #49988E;
                color: #fff;
                padding: 10px 16px;
                line-height: 20px;
                display: inline-block !important;
            }
            .button-cancel{
                margin-right: 12px;
                background-color: #EAF0F3 !important;
                color: #394656;
                filter: brightness(1) !important;
            }
        }
    }
    .head{
        background: #F5F5F5;
    }
    .popover-arrow{
        display: block !important;
        transform: translatex(-100%) !important;
        left: calc(100% - 32px) !important;
        top: -8px !important;
        &::before{
            border-bottom-color: #F5F5F5 !important;
            top: 1px;
        }
        &::after{
            border-top-color: #fff !important;
            top: 2px;
        }
    }
}

.popover--inner {
    .popover-rows-wrapper {
        padding: 7px 16px 0;
        .label {
            color: #959595;
        }
        .popover-row {
            display: flex;
            padding: 7px 0;
            gap: 6px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            .range {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-top: 4px;
            }
        }
    }
}
