@use "./variables.scss";


// im webfont
.im-icon{
    transition: .2s ease;
    font-size: variables.$fontsize-l;
    &.icon-xxl{
        font-size: 18px;
    }
    &.icon-xl{
        font-size: 16px;
    }
    &.icon-l{
        font-size: 14px;
    }
    &.icon-s{
        font-size: 9px;
    }
    &.icon-xs{
        font-size: 7px;
    }
    &.icon-xxs{
        font-size: 6px;
    }
}

// customize
.icon-close{
    color: #acacac;
    font-size: 24px;
}

// help tooltip
.icon-help{
    color: #83aae4;
    font-size: 12px;
}

// action icons
.action-icon{
    width: 56px;
    height: 56px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 2px solid #efefef;
    transition: .2s ease;
    cursor: pointer;
    i{
        font-size: 25px;
    }
}

.icon{
    flex-shrink: 0;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &.icon-notif{
        background-image: url(/assets/img/icons/notif.svg);
        width: 24px;
        height: 24px;
    }
    &.icon-expand{
        background-image: url(/assets/img/icons/expand.svg);
        width: 24px;
        height: 24px;
    }
    &.icon-expand_blue{
        background-image: url(/assets/img/icons/expand_blue.svg);
        width: 24px;
        height: 24px;
    }
    // &.icon-file{
    //     background-image: url(/assets/img/icons/files/file.svg);
    //     width: 24px;
    //     height: 24px;
    //     transform: translateY(1px);
    // }
    // &.icon-docx{
    //     background-image: url(/assets/img/icons/files/docx.svg);
    //     width: 24px;
    //     height: 24px;
    //     transform: translateY(1px);
    // }
    // &.icon-eml{
    //     background-image: url(/assets/img/icons/files/eml.svg);
    //     width: 24px;
    //     height: 24px;
    //     transform: translateY(1px);
    // }
    // &.icon-xlsx{
    //     background-image: url(/assets/img/icons/files/xlsx.svg);
    //     width: 24px;
    //     height: 24px;
    //     transform: translateY(1px);
    // }
    // &.icon-key{
    //     background-image: url(/assets/img/icons/files/key.svg);
    //     width: 24px;
    //     height: 24px;
    //     transform: translateY(1px);
    // }
    // &.icon-jpeg{
    //     background-image: url(/assets/img/icons/files/jpeg.svg);
    //     width: 24px;
    //     height: 24px;
    //     transform: translateY(1px);
    // }
    // &.icon-xls{
    //     background-image: url(/assets/img/icons/files/xls.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    // &.icon-pptx{
    //     background-image: url(/assets/img/icons/files/pptx.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    // &.icon-jpg{
    //     background-image: url(/assets/img/icons/files/jpg.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    // &.icon-ppt{
    //     background-image: url(/assets/img/icons/files/ppt.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    // &.icon-num{
    //     background-image: url(/assets/img/icons/files/num.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    // &.icon-png{
    //     background-image: url(/assets/img/icons/files/png.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    // &.icon-csv{
    //     background-image: url(/assets/img/icons/files/csv.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    // &.icon-msg{
    //     background-image: url(/assets/img/icons/files/msg.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    // &.icon-doc{
    //     background-image: url(/assets/img/icons/files/doc.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    // &.icon-pdf{
    //     background-image: url(/assets/img/icons/files/pdf.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    // &.icon-lock{
    //     background-image: url(/assets/img/icons/icon-lock.svg);
    //     width: 18px;
    //     height: 18px;
    // }
    // &.icon-folder{
    //     background-image: url(/assets/img/icons/icon-folder.svg);
    //     width: 24px;
    //     height: 24px;
    // }
    &.icon-arrow-down-right{
        background-image: url(/assets/img/icons/arrow-down-right.svg);
        width: 16px;
        height: 16px;
    }
}