@use "./variables.scss";

// modals
.modal{
    .close-modal-outside{
        position: absolute;
        right: 3px;
        top: -28px;
        cursor: pointer;
        transition: none;
        .im-icon{
            transition: none;
            font-size: 16px;
            color: #fff;
        }
    }
    &.main-modal .modal-dialog{
        max-width: 600px;
    }
    &.detail-modal .modal-dialog{
        width: calc(100% - #{variables.$spacing-xxl});
        height: calc(100% - #{variables.$spacing-xxl});
        max-width: calc(1920px - #{variables.$spacing-xxl});
        margin-top: 64px;
        margin-bottom: variables.$spacing-m;
        .modal-content{
            height: 100%;
        }
    }
    &.detail-modal-overlay .modal-dialog{
        width: calc(100% - #{variables.$spacing-xxl});
        height: calc(100% - #{variables.$spacing-xxl});
        max-width: 1200px;
        margin-top: 128px;
        margin-bottom: variables.$spacing-l;
        .modal-content{
            height: 100%;
        }
    }
    &.detail-modal--create .modal-dialog{
        max-width: calc(1920px - #{variables.$spacing-xxl + variables.$sidePanelWidth});
    }
    &.detail-modal--tab-validate .modal-dialog{
        max-width: calc(1920px - #{variables.$spacing-xxl + variables.$sidePanelWidth});
    }
    &.confirm-modal{
        .modal-content{
            min-height: unset !important;
            padding: 30px;
        }
        .modal-dialog{
            margin-top: 140px;
        }
    }
    &.sub-modal .modal-dialog{
        width: calc(100% - #{variables.$spacing-xxl});
        height: calc(100% - #{variables.$spacing-xxl});
        max-width: calc(1500px - #{variables.$spacing-xxl});
        margin-top: variables.$spacing-m;
        margin-bottom: variables.$spacing-m;
        .modal-content{
            height: 100%;
        }
    }
    &.options-modal .modal-dialog{
        max-width: 800px;
        .modal-content{
            min-height: 420px;
        }
    }
    &.table-column-options-modal .modal-dialog{
        max-width: 440px;
        .modal-content{
            min-height: 0px;
        }
    }
    &.confirm-action-modal {
        cursor: default;
        display: flex !important;
        align-items: center;
        justify-content: center;
        .modal-dialog{
            max-width: 500px;
            margin: 0;
            margin-bottom: 160px;
            .modal-content{
                min-height: 0px;
            }
        }
    }
    .modal-dialog{
        height: auto !important;
        // height: calc(100% - #{variables.$spacing-xxl}) !important;
        overflow: auto !important;
        border-radius: 6px !important;
        margin-top: 80px;
        box-shadow: 0px 0px 16px 0px rgb(0 0 0 / 20%);
        overflow: visible !important;
        .modal-content{
            overflow: visible !important;
            border-radius: 6px !important;
            border: none;
            overflow-x: hidden;
            min-height: 600px;
        }
        .modal-header {
            border: none;
            align-items: center;
        }
        .modal-body {
            border: none;
        }
        .modal-footer {
            border: none;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .modal-wrap{
            border-radius: 6px;
            // overflow: hidden;
            background-color: #fff;
        }
    }

    // captcha scss
    &.modal--captcha .modal-dialog{
        max-width: 620px;
        .modal-content{
            min-height: 0 !important;
        }
    }
}

.modal-backdrop{
    background-color: #5e5e5e !important;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    // opacity: 1 !important;
    &.modal-backdrop--route{
        z-index: 1040 !important;
    }
}
.modal--route{
    z-index: 1041 !important;
    .close-modal-area{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
.modal--confirm{
    z-index: 1042 !important;
}