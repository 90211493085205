@use "./variables.scss";

.table-wrap{
    margin-left: -40px;
    margin-right: -16px;
    padding-left: 40px;
    padding-right: 16px;
    width: calc(100% + 56px);
    max-width: calc(100% + 56px);
    padding-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
}
.table-component{
    position: relative;
    .data-ellipsis{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
    .column-options-button{
        position: relative;
        cursor: pointer;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 20px;
        transform: translateY(-1px);
        min-width: 24px;
        &>i{
            color: #4F5563;
            font-size: 16px;
        }
    }
    position: relative;
    &.loading-timed-out{
        pointer-events: none;
        .loading-timed-out-spinner{
            z-index: 1;
            position: absolute;
            // top: 200px;
            // left: 50%;
            // transform: translateX(-50%);
            top: 38px;
            bottom: 0;
            transform: none;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 400px;
        }
        td{
            transition: .3s ease !important;
            opacity: .2 !important;
        }
    }
}
.table--padded{
    td{
        padding: 0 !important;
        height: 1px;
        .pad{
            padding: 16px 14px;
            text-decoration: none!important;
            display: block;
            height: 100%;
            width: 100%;
            line-height: 24px;
        }
        &.td--actions{
            .pad{
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }
        &.td--flags{
            .pad{
                padding-top: 20px;
                padding-bottom: 20px;
            }
            .flags{
                display: flex;
                .flag{
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                    &:last-of-type{
                        margin-right: 0;
                    }
                    &>i{
                        transform: translate(1px);
                        font-size: 16px;
                    }
                }
            }
        }
        &.td--buttons-sm{
            .pad{
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }
        &.td--12{
            .pad{
                font-size: 12px;
            }
        }
        &.td--avatar{
            .pad{
                padding-top: 14px;
                padding-bottom: 14px;
                span.title-after-avatar{
                    margin-left: 8px;
                    display: inline-block;
                    // transform: translateY(1px);
                }
            }
        }
        &:first-of-type .pad{
            padding-left: 0;
        }
        &:last-of-type .pad{
            padding-right: 0;
        }
    }
    .shimmer-body{
        tr{
            td{
                padding: 16px 0 !important;
            }
        }
    }
}
.modal-wrap{
    .table{
        td{
            &:last-of-type::after{
                right: -40px;
                width: 40px;
            }
        }
    }
}
body div .table{
    margin-bottom: 0;
    .shrink{
        width: .1%;
    }
    th, td{
        vertical-align: top;
        padding-left: 14px;
        padding-right: 14px;
        line-height: 21px;
        position: relative;
        &.center {
            text-align: center;
        }
    }
    td{
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #F3F3F3;
        border-top: none;
        font-size: variables.$fontsize-s;
        font-weight: 400;

        &.td--mw-0{
            max-width: 0;
        }
        .title{
            color: variables.$text-color;
            font-weight: 500;
        }
        .subtitle{
            margin-top: 1px;
            font-size: 12px;
            line-height: 16px;
        }
        .low-opacity {
            color: #ACB5C1;
        }
        &:first-of-type::after{
            content: '';
            position: absolute;
            background: #f1f9fe;
            top: -1px;
            left: -#{variables.$spacing-xs};
            height: calc(100% + 2px);
            width: variables.$spacing-xs;
            opacity: 0;
        }
        &:last-of-type::after{
            content: '';
            position: absolute;
            background: #f1f9fe;
            top: -1px;
            right: -#{variables.$spacing-xs};
            height: calc(100% + 2px);
            width: variables.$spacing-xs;
            opacity: 0;
        }
        .center {
            width: 100%;
        }
    }
    th{
        padding-top: 8px;
        padding-bottom: 8px;
        z-index: 1;
        cursor: default;
        &.sortable{
            cursor: pointer;
            user-select: none;
        }
        &.settings{
            cursor: pointer;
            display: table-cell;
            padding-left: 5px;
        }
        &:first-of-type{
            padding-left: 0;
        }
        &:last-of-type{
            padding-right: 0;
        }
        &>div{
            white-space: nowrap;
        }
        color: variables.$primary-color;
        font-weight: 500;
        font-size: variables.$fontsize-s;
        border-top: none;
        &>div{
            z-index: 2;
        }
        border-bottom: 2px solid #E2E3E6;
        i.sortable-icon{
            position: relative;
            margin-left: 6px;
            &::after{
                content: "";
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 5px solid #E2E3E6;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 3px;
            }
            &::before{
                content: "";
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 5px solid #E2E3E6;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin-top: -3px;
            }
            &.sort-asc{
                &::before{
                    border-bottom: 5px solid variables.$delete-red;
                }
            }
            &.sort-desc{
                &::after{
                    border-top: 5px solid variables.$delete-red;
                }
            }
        }
    }
    tr{
        transition: .1s ease;
        position: relative;
        &.clickable{
            cursor: pointer;
            &:hover td{
                background-color: variables.$hover-row--lighter;
                &:first-of-type::after{
                    opacity: 1;
                }
                &:last-of-type::after{
                    opacity: 1;
                }
            }
            &.active td{
                background-color: variables.$hover-row--lighter;
                .title{
                    // color: variables.$primary-color;
                    color: variables.$secondary-color;
                }
                &:first-of-type::after{
                    opacity: 1;
                }
                &:last-of-type::after{
                    opacity: 1;
                }
            }
        }
        td {
            transition: 0s;
            color: #717277;
            a{
                color: #717277;
            }
            &:first-of-type::after{
                content: '';
                position: absolute;
                top: -1px;
                left: -40px;
                height: calc(100% + 2px);
                width: 40px;
                opacity: 0;
                border-top: 1px solid #F3F3F3;
                border-bottom: 1px solid #F3F3F3;
                background-color: variables.$hover-row--lighter;
            }
            &:last-of-type::after{
                content: '';
                position: absolute;
                top: -1px;
                right: -16px;
                height: calc(100% + 2px);
                width: 16px;
                opacity: 0;
                border-top: 1px solid #F3F3F3;
                border-bottom: 1px solid #F3F3F3;
                background-color: variables.$hover-row--lighter;
            }
            &:first-of-type{
                padding-left: 0;
            }
            &:last-of-type{
                padding-right: 0;
            }
        }
        &:first-of-type td{
            // &:first-of-type::after{
            //     border-top-color: #E2E3E6;
            // }
            // &:last-of-type::after{
            //     border-top-color: #E2E3E6;
            // }
        }
    }

    .bullet--new:before{
        content: "•";
        color: #569ff9;
        font-size: x-large;
        margin-left: 6px;
        transform: translateY(3px);
        display: inline-block;
    }
}

.td-filename{
    padding-left: 8px !important;
}

.table-empty-line{
    td{
        border-bottom: none !important;
    }
}

.action-button{
    cursor: pointer;
    color: variables.$text-color;
    font-size: variables.$fontsize-xxs;
    text-decoration: none !important;
    background-color: #EEF2F5;
    background-color: variables.$hover-row;
    width: 24px;
    height: 24px;
    border-radius: 99px;
    transition: .1s ease;
    &.action-active{
        background-color: #EEF2F5 !important;
        background-color: variables.$hover-row !important;
    }
    &:hover, &.active{
        background-color: #dde5ea;
    }
    &>i{
        font-size: 14px;
        padding: 1px 5px;
    }
}

.table--detail {
    width: 100%;
    .app-list{
        margin-bottom: 0 !important;
        padding-top: 10px !important;
        li{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: none !important;
            border-top: 1px solid #E6E6E6;
            // color: #7C8590 !important;
            color: #acacac !important;
            &:last-of-type{
                padding-bottom: 0;
            }
        }
    }

    &.table--detail--clickable{
        &>tr{
            &>td{
                cursor: pointer;
            }
            &:hover{
                td{
                    background-color: rgba(244, 246, 249, .4);
                }
            }
        }
    }

    &>tr{
        &>th{
            white-space: nowrap;
            color: variables.$primary-color;
            font-size: variables.$fontsize-s;
            line-height: 21px;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 8px;
            padding-bottom: 8px;
            background-color: #F9F9FA;
            &:first-of-type{
                border-top-left-radius: 4px;
                padding-left: 14px !important;
                &::after {
                    background-color: #F9F9FA;
                    border-top-left-radius: 4px;
                    border-left: 1px solid variables.$secondary-color-lightest !important;
                    border-top: 1px solid variables.$secondary-color-lightest !important;
                    border-bottom: 1px solid variables.$secondary-color-lightest !important;
                    left: 0;
                }
            }
            &:last-of-type{
                border-top-right-radius: 4px;
                &::after {
                    background-color: #F9F9FA;
                    border-top-right-radius: 4px;
                    border-right: 1px solid variables.$secondary-color-lightest !important;
                    border-top: 1px solid variables.$secondary-color-lightest !important;
                    border-bottom: 1px solid variables.$secondary-color-lightest !important;
                    right: 0;
                }
            }
            border-bottom: 1px solid variables.$secondary-color-lightest !important;
            border-top: 1px solid variables.$secondary-color-lightest !important;
        }
        &>td{
            transition: .1s ease;
            font-size: variables.$fontsize-s;
            line-height: 21px;
            border-bottom: 1px solid #E6E6E6;
            border-top: none;
            color: #7C8590;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 12px;
            padding-right: 12px;
            &.td--label{
                color: variables.$secondary-color;
            }
            &.td-passive{
                color: #949494 !important;
            }
            &:first-of-type{
                padding-left: 14px !important;
                border-left: 1px solid variables.$secondary-color-lightest;
                &::after {
                    position: initial !important;
                }
            }
            &:last-of-type{
                padding-right: 14px !important;
                border-right: 1px solid variables.$secondary-color-lightest;
                &::after {
                    position: initial !important;
                }
            }
            .title{
                display: block;
            }
            .subtitle{
                width: 100%;
                display: block;
                color: #717277;
                font-weight: 300;
            }
        }
        &:last-of-type {
            &>td:first-of-type {
                border-bottom-left-radius: 4px;
            }
            td:last-of-type {
                border-bottom-right-radius: 4px;
            }
        }
    }
    &.table--detail--form{
        &>tr{
            &>th{
                padding-top: 8px;
                padding-bottom: 8px;
                // border-bottom: none !important;
                &:first-of-type{
                    // padding-left: 0;
                }
                &:last-of-type{
                    // padding-right: 16px;
                }
            }
            &>td{
                padding-top: 8px;
                padding-bottom: 8px;
                // border-bottom: none;
                &:first-of-type{
                    // padding-left: 0;
                }
                &:last-of-type{
                    // padding-right: 16px;
                }
            }
        }
    }
    &.table--detail--dash{
        border-radius: 4px;
        border: 1px solid #EAF0F3;
        background-color: #fff;
        border-collapse: separate;
        &>tr{
            &>th{
                line-height: 21px;
                padding: 10px 16px;
                background-color: transparent;
                border-bottom: 1px solid #EBEBEC;
                font-weight: 500;
            }
            &>td{
                line-height: 21px;
                padding: 12px 16px;
                border-bottom: 1px solid #E9EEF0;
                color: #717277;
                background-color: #FCFCFF;
                background-color: #fff;
                strong{
                    color: variables.$text-color;
                    font-weight: 500;
                }
                &.td--status{
                    span{
                        color: #4F5563;
                    }
                }
            }
            &:last-of-type td{
                border-bottom: none;
                &:first-of-type{
                    border-bottom-left-radius: 4px;
                }
                &:last-of-type{
                    border-bottom-right-radius: 4px;
                }
            }
            &:last-of-type th{
                &:first-of-type{
                    border-top-left-radius: 4px;
                }
                &:last-of-type{
                    border-top-right-radius: 4px;
                }
            }
            // &:nth-of-type(2n) td{
            //     background-color: #fff;
            // }
        }
        &.table--detail--dash--buttons{
            &>tr{
                &>th{
                    background-color: #F9F9FA;
                }
                &>td{
                    color: #989898;
                    vertical-align: middle;
                }
            }
        }
    }
    &.table--detail--slideout{
        &>tr{
            &>th{
                background-color: transparent;
                border-bottom: 1px solid #EBEBEC;
                padding-top: 6px;
                padding-bottom: 6px;
                &:first-of-type{
                    padding-left: 0;
                }
                &:last-of-type{
                    padding-right: 0;
                }
            }
            &>td{
                vertical-align: middle;
                line-height: 24px;
                padding-top: 6px;
                padding-bottom: 6px;
                border-bottom: 1px solid #E9EEF0;
                color: #3D3D3D;
                font-weight: 300;
                &:first-of-type{
                    padding-left: 0;
                }
                &:last-of-type{
                    padding-right: 0;
                }
            }
        }
    }
}


.slideout-wrap{
    position: relative;
    background-color: #F6F6F6;
    // padding: 32px 24px 64px 92px;
    position: fixed;
    // overflow-y: auto;
    right: -800px;
    top: 0;
    height: 100vh;
    width: 800px;
    z-index: 999;
    transition: right .4s ease, opacity .3s ease;
    opacity: 0;
    pointer-events: none;
    min-width: 180px;
    max-width: calc(100vw - 300px);
    &.open{
        right: 0;
        box-shadow: -12px 0px 16px rgba(0, 0, 0, 0.12);
        opacity: 1;
        pointer-events: all;
    }
    .slideout-wrap-inner{
        overflow-y: auto;
        padding: 32px 24px 64px 24px;
        height: 100%;
    }
    .button-collapse{
        top: 36px;
        left: 0;
        transform: translateX(-50%);
    }
    .head-wrap{
       display: flex;
       justify-content: space-between;
       align-items: flex-start;
       padding-bottom: 32px;
        .title-wrap{
            padding-right: 40px;
            .title{
                font-size: 20px;
                line-height: 28px;
                color: variables.$text-color;
                font-weight: 500;
                word-break: break-word;
            }
            .sub{
                color: #717277;
                font-size: 16px;
                line-height: 26px;
                font-weight: 300;
                .sep-dot{
                    width: 4px;
                    height: 4px;
                    display: inline-block;
                    margin: 3px 6px;
                    background-color: variables.$secondary-color;
                    border-radius: 100%;
                }
            }
        }
    }
    .resize{
        left: -2px;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: variables.$primary-color;
        position: absolute;
        cursor: ew-resize;
        opacity: 0;
        transition: .1s;
        &:hover{
            opacity: .5;
        }
    }
    &.resizing{
        user-select: none;
        *{
            pointer-events: none;
        }
        .resize{
            opacity: 1;
            pointer-events: all !important;
        }
    }
}