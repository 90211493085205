@use "./variables.scss";

.ql-container{
    font-size: 100%;
}

.ql-container .ql-editor{
    display: block;
    width: 100%;
    padding: 8px 15px;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    // min-height: 64px;
}

quill-editor .ql-toolbar.ql-snow{
    border: 1px solid variables.$field-border-color !important;
    border-bottom: none !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}
quill-editor .ql-container.ql-snow{
    border: 1px solid variables.$field-border-color !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.products quill-editor .ql-container.ql-snow{
    background: #FFF;
}

.ql-editor.ql-blank::before{
    font-style: normal;
    color: #ACB5C1;
}

.richtext-output{
    display: block;
    .ql-editor{
        padding: 0;
    }
    p{
        color: #4F5563;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
}

.field-richtext{
    .ql-toolbar.ql-snow{
        display: none !important;
    }
    &.field-richtext--expanded{
        .ql-toolbar.ql-snow{
            display: block !important;
        }
        quill-editor .ql-container.ql-snow{
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
        .ql-editor{
            min-height: 64px;
        }
    }
}

// wrap w/ buttons
.richtext-wrap{
    position: relative;
    // .ql-container .ql-editor{
    //     padding-right: 40px;
    // }
    // .add-comment-button{
    //     position: absolute;
    //     width: 40px;
    //     height: 40px;
    //     border-radius: 4px;
    //     display: inline-flex;
    //     justify-content: center;
    //     align-items: center;
    //     bottom: 1px;
    //     right: 0;
    //     z-index: 1;
    //     cursor: pointer;
    //     &>i{
    //         transition: .1s ease;
    //         font-size: 24px;
    //         color: #DFECEF;
    //     }
    //     &:hover i{
    //         color: variables.$secondary-color-lighter !important;
    //     }
    // }
    .richtext-buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 12px;
        justify-content: flex-start;
        .button--confirm{
            margin-right: 16px;
        }
        .button--cancel{}
    }
}