@use "./variables.scss";
// bootstrap
.tooltip-arrow{
    display: none !important;
}
.tooltip{
    opacity: 1 !important;
    // &.bs-tooltip-bottom{
    //     .tooltip-arrow{
    //         width: 9px;
    //         height: 6px;
    //         left: 50% !important;
    //         transform: translateX(-50%);
    //         &::before{
    //             border-width: 4px;
    //             border-bottom-color: variables.$text-color;
    //         }
    //     }
    // }
    // &.bs-tooltip-top{
    //     .tooltip-arrow{
    //         width: 9px;
    //         height: 6px;
    //         left: 50% !important;
    //         transform: translateX(-50%) translateY(-1px);
    //         &::before{
    //             border-width: 4px;
    //             border-top-color: variables.$text-color;
    //         }
    //     }
    // }
    &.bs-tooltip-start{
        .tooltip-arrow{
            // width: 6px;
            // height: 10px;
            // top: 50% !important;
            // transform: translateY(-50%) translateX(-1px);
            // transform: translatey(-50%) !important;
            // top: 50% !important;
            // &::before{
            //     // border-width: 4px;
            //     border-left-color: variables.$text-color;
            //     transform: translatey(-50%);
            //     top: 50%;
            // }
        }
    }
    .tooltip-inner{
        padding: 8px 16px;
        font-size: 14px;
        line-height: 24px;
        width: 240px;
        width: max-content;
        max-width: 300px;
        background-color: variables.$text-color;
        border-radius: 8px;
        text-align: left;
    }
    &.tooltip--flag{
        .tooltip-arrow{
            display: none !important;
        }
        .tooltip-inner{
            padding: 16px;
            background-color: #394656;
            opacity: .9;
            border-radius: 4px;
            width: 340px;
            width: max-content;
            max-width: 400px;
            .title{
                display: flex;
                align-items: center;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                color: #fff;
                &>i{
                    font-size: 16px;
                    margin-right: 6px;
                    color: #fff;
                }
            }
            .descr{
                margin-top: 8px;
                color: #fff;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
            }
        }
    }
}

// .tip {
//     position: relative;
// }

// .tip::before,
// .tip::after {
//     left: 50%;
//     opacity: 0;
//     position: absolute;
//     pointer-events: none;
// }

// .tip:hover::before,
// .tip:focus::before,
// .tip:hover::after,
// .tip:focus::after {
//     opacity: 1;
// }

// .tip::before {
//     // border-style: solid;
//     // border-width: 1em 0.75em 0 0.75em;
//     // border-color: #3E474F transparent transparent transparent;
//     // bottom: 100%;
//     // content: "";
//     // margin-left: -0.5em;
//     // transform:  scale(.6) translateY(-90%);
// } 

// .tip:hover::before,
// .tip:focus::before {
//     // transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
// }

// .tip::after {
//     background: #050505;
//     border-radius: 3px;
//     bottom: 100%;
//     color: #fff;
//     content: attr(data-tip);
//     padding: 6px 8px;
//     font-size: 14px;
//     transform: translatex(-50%) translatey(-6px);
//     transition: .2s ease;
//     white-space: nowrap;
// }
// .tip-right::after{
//     bottom: auto;
//     left: 100%;
//     transform: translatex(3px) translatey(-52%);
//     top: 50%;
// }

// .tooltip-indicator{
//     position: relative;
//     &::after{
//         content: "";
//         border-bottom: 1px dashed variables.$text-color;
//         position: absolute;
//         width: 100%;
//         left: 0;
//         bottom: 2px;
//     }
// }

 // full tooltip
 .app-tooltip-wrap{
     position: relative;
     cursor: pointer;
     &:hover .app-tooltip, &:focus .app-tooltip{
         opacity: 1;
         pointer-events: all;
     }
     .app-tooltip{
         // max-width: 150px;
         white-space: initial;
         font-weight: 400;
         opacity: 0;
         // opacity: 1;
         pointer-events: none;
         transition: .15s ease;
         position: absolute;
         z-index: 1;
         bottom: calc(100% + 6px);
         right: -40px;
         // min-width: 200px;
         //max-width: 200px;
         width: 200px;
         width: max-content;
         border-bottom: 1px solid #F39235;
         background: #fef6f0;
         box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2) !important;
         font-size: 12px;
         line-height: 14px;
         padding: 6px 11px 5px 11px;
         border: none;
         border-radius: 4px;
         &::after{
             content: '';
             border-style: solid;
             border-width: 4px 4px 4px 4px;
             border-width: 5px;
             border-color: #F39235 transparent transparent;
             position: absolute;
             top: calc(100% + 0px);
             right: 47px;
         }
         &.app-tooltip-default-centered{
             background-color: white;
             border-color: variables.$gray;
             color: variables.$text-color;
             right: 50%;
             transform: translatex(50%);
             &::after{
                 border-color: #fff transparent transparent;
                 right: 50%;
                 transform: translatex(50%);
             }
         }
         &.app-tooltip-left-nowrap{
             background-color: white;
             border-color: variables.$gray;
             color: variables.$text-color;
             top: 50%;
             bottom: auto;
             transform: translatey(-50%);
             border: none;
             border-right: 2px solid variables.$gray;
             min-width: 0;
             white-space: nowrap;
             right: calc(100% + 7px);
             left: auto;
             &::after{
                 border-color: variables.$gray transparent transparent;
                 right: -10px;
                 transform: translateY(-50%) rotate(
             -90deg
             );
                 top: 50%;
                 bottom: auto;
                 left: auto;
             }
         }
     }
 }
 