@use "./variables.scss";

.primary-color { color: variables.$primary-color !important; }
.blue { color: variables.$secondary-color !important; }
.green { color: #5fbb97 !important; }
.red { color: variables.$error-red !important; }
.delete-red { color: variables.$delete-red !important; }
.passive-gray { color: #909090 !important; }
.passive-gray-light { color: #acacac !important; }
.text-color { color: variables.$text-color !important; }

.no-wrap{
    white-space: nowrap;
}
.pre-wrap{
    white-space: pre-wrap;
}
.table-scroll{
    padding-bottom: 30px;
    overflow-y: hidden;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
.underline{
    text-decoration: underline !important;
}
.disabled-cursor{
    cursor: not-allowed;
}

.fontsize-xl {font-size: variables.$fontsize-xl};
.fontsize-l {font-size: variables.$fontsize-l};
.fontsize-m {font-size: variables.$fontsize-m};
.fontsize-s {font-size: variables.$fontsize-s};
.fontsize-xs {font-size: variables.$fontsize-xs};
.fontsize-xxs {font-size: variables.$fontsize-xxs};

.transform-y--1{
    transform: translateY(-1px);
}
.transform-x--1{
    transform: translateX(-1px);
}
.transform-y-1{
    transform: translateY(1px);
}
.transform-x-1{
    transform: translateX(1px);
}

.w-200-px{
    width: 200px;
}
.w-250-px{
    width: 250px;
}
.w-300-px{
    width: 300px;
}
.w-350-px{
    width: 350px;
}
.w-400-px{
    width: 400px;
}
.w-450-px{
    width: 450px;
}
.w-500-px{
    width: 500px;
}

.mw-200-px{
    max-width: 200px;
}
.mw-250-px{
    max-width: 250px;
}
.mw-300-px{
    max-width: 300px;
}
.mw-350-px{
    max-width: 350px;
}
.mw-400-px{
    max-width: 400px;
}
.mw-450-px{
    max-width: 450px;
}
.mw-500-px{
    max-width: 500px;
}
.mw-550-px{
    max-width: 550;
}
.mw-600-px{
    max-width: 600px;
}
.mw-650-px{
    max-width: 650px;
}
.mw-700-px{
    max-width: 700px;
}
.mw-750-px{
    max-width: 750px;
}
.mw-800-px{
    max-width: 800px;
}
.mw-850-px{
    max-width: 850px;
}
.mw-900-px{
    max-width: 900px;
}
.mw-950-px{
    max-width: 950px;
}
.mw-1000-px{
    max-width: 1000px;
}
.mw-1050-px{
    max-width: 1050px;
}
.mw-1100-px{
    max-width: 1100px;
}
.mw-1150-px{
    max-width: 1150px;
}
.mw-1200-px{
    max-width: 1200px;
}
.mw-1250-px{
    max-width: 1250px;
}
.mw-1300-px{
    max-width: 1300px;
}
.mw-1350-px{
    max-width: 1350px;
}
.mw-1400-px{
    max-width: 1400px;
}
.mw-1450-px{
    max-width: 1450px;
}

// spacing padding helper classes
.p-xl { padding: variables.$spacing-xl !important; }
.p-l { padding: variables.$spacing-l !important; }
.p-m { padding: variables.$spacing-m !important; }
.p-s { padding: variables.$spacing-s !important; }
.p-xs { padding: variables.$spacing-xs !important; }
.p-xxs { padding: variables.$spacing-xxs !important; }
.p-xxxs { padding: variables.$spacing-xxxs !important; }

.p-xxl { padding: variables.$spacing-xxl; }
.pt-xxl { padding-top: variables.$spacing-xxl; }
.pl-xxl { padding-left: variables.$spacing-xxl; }
.pr-xxl { padding-right: variables.$spacing-xxl; }
.pb-xxl { padding-bottom: variables.$spacing-xxl; }
.pt-xl { padding-top: variables.$spacing-xl !important; }
.pl-xl { padding-left: variables.$spacing-xl !important; }
.pr-xl { padding-right: variables.$spacing-xl !important; }
.pb-xl { padding-bottom: variables.$spacing-xl !important; }
.pt-l { padding-top: variables.$spacing-l !important; }
.pl-l { padding-left: variables.$spacing-l !important; }
.pr-l { padding-right: variables.$spacing-l !important; }
.pb-l { padding-bottom: variables.$spacing-l !important; }
.pt-m { padding-top: variables.$spacing-m !important; }
.pl-m { padding-left: variables.$spacing-m !important; }
.pr-m { padding-right: variables.$spacing-m !important; }
.pb-m { padding-bottom: variables.$spacing-m !important; }
.pt-s { padding-top: variables.$spacing-s !important; }
.pl-s { padding-left: variables.$spacing-s !important; }
.pr-s { padding-right: variables.$spacing-s !important; }
.pb-s { padding-bottom: variables.$spacing-s !important; }
.pt-xs { padding-top: variables.$spacing-xs !important; }
.pl-xs { padding-left: variables.$spacing-xs !important; }
.pr-xs { padding-right: variables.$spacing-xs !important; }
.pb-xs { padding-bottom: variables.$spacing-xs !important; }
.pt-xxs { padding-top: variables.$spacing-xxs !important; }
.pl-xxs { padding-left: variables.$spacing-xxs !important; }
.pr-xxs { padding-right: variables.$spacing-xxs !important; }
.pb-xxs { padding-bottom: variables.$spacing-xxs !important; }
.pt-xxxs { padding-top: variables.$spacing-xxxs !important; }
.pl-xxxs { padding-left: variables.$spacing-xxxs !important; }
.pr-xxxs { padding-right: variables.$spacing-xxxs !important; }
.pb-xxxs { padding-bottom: variables.$spacing-xxxs !important; }

// spacing margin helper classes
.m-xl { margin: variables.$spacing-xl !important; }
.m-l { margin: variables.$spacing-l !important; }
.m-m { margin: variables.$spacing-m !important; }
.m-s { margin: variables.$spacing-s !important; }
.m-xs { margin: variables.$spacing-xs !important; }
.m-xxs { margin: variables.$spacing-xxs !important; }
.m-xxxs { margin: variables.$spacing-xxxs !important; }

.m-xxl { margin: variables.$spacing-xxl; }
.mt-xxl { margin-top: variables.$spacing-xxl; }
.ml-xxl { margin-left: variables.$spacing-xxl; }
.mr-xxl { margin-right: variables.$spacing-xxl; }
.mb-xxl { margin-bottom: variables.$spacing-xxl; }
.mt-xl { margin-top: variables.$spacing-xl !important; }
.ml-xl { margin-left: variables.$spacing-xl !important; }
.mr-xl { margin-right: variables.$spacing-xl !important; }
.mb-xl { margin-bottom: variables.$spacing-xl !important; }
.mt-l { margin-top: variables.$spacing-l !important; }
.ml-l { margin-left: variables.$spacing-l !important; }
.mr-l { margin-right: variables.$spacing-l !important; }
.mb-l { margin-bottom: variables.$spacing-l !important; }
.mt-m { margin-top: variables.$spacing-m !important; }
.ml-m { margin-left: variables.$spacing-m !important; }
.mr-m { margin-right: variables.$spacing-m !important; }
.mb-m { margin-bottom: variables.$spacing-m !important; }
.mt-s { margin-top: variables.$spacing-s !important; }
.ml-s { margin-left: variables.$spacing-s !important; }
.mr-s { margin-right: variables.$spacing-s !important; }
.mb-s { margin-bottom: variables.$spacing-s !important; }
.mt-xs { margin-top: variables.$spacing-xs !important; }
.ml-xs { margin-left: variables.$spacing-xs !important; }
.mr-xs { margin-right: variables.$spacing-xs !important; }
.mb-xs { margin-bottom: variables.$spacing-xs !important; }
.mt-xxs { margin-top: variables.$spacing-xxs !important; }
.ml-xxs { margin-left: variables.$spacing-xxs !important; }
.mr-xxs { margin-right: variables.$spacing-xxs !important; }
.mb-xxs { margin-bottom: variables.$spacing-xxs !important; }
.mt-xxxs { margin-top: variables.$spacing-xxxs !important; }
.ml-xxxs { margin-left: variables.$spacing-xxxs !important; }
.mr-xxxs { margin-right: variables.$spacing-xxxs !important; }
.mb-xxxs { margin-bottom: variables.$spacing-xxxs !important; }

.mt-none{margin-top: 0 !important;}
.pl-none{padding-left: 0 !important;}

// font weights
.fontweight-300 { font-weight: 300; }
.fontweight-400 { font-weight: 400; }
.fontweight-500 { font-weight: 500; }
.fontweight-600 { font-weight: 500; }