@use "./variables.scss";

.modal-wrap{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    h2{
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
        color: #394656;
    }
    h3{
        color: #4F5563;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
    }
    .back-txt{
        margin-left: 11px;
        line-height: 21px;
        transform: translateY(-2px);
        display: inline-block;
    }
    .sep{
        border-top: 1px solid #E2E3E6;
        margin-top: 32px;
        padding-bottom: 64px;
        &.sep--form{
            margin-top: 40px;
            padding-bottom: 64px;
        }
        &.sep--light{
            border-color: #F3F3F3;
            padding-bottom: 32px;
            margin-top: 0;
            &.sep--light--form{
                margin-top: 16px;
            }
        }
    }

    .side-nav-wrap{
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .panel-head{
        border-bottom: 1px solid #506074;
        button, .back-btn{
            color: #fff !important;
            text-decoration: none !important;
            display: inline-block;
            i{
                color: #fff !important;
                text-decoration: none !important;
            }
        }
        .titles{
            .title{
                font-weight: 500;
                font-size: 18px;
                // line-height: 27px;
                margin-bottom: 0;
                margin-right: 8px;
                line-height: 23px;
            }
            .subtitle{
                font-size: variables.$fontsize-s;
                line-height: 21px;
                color: #b0b5bb;
                // margin-top: 2px;
            }
        }
    }
    .detail-modal-header{
        padding: 32px 40px;
        background-color: #F9F9FA;
        position: sticky;
        top: 0;
        z-index: 1;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        h1{
            font-size: 32px;
            line-height: 48px;
            font-weight: 500;
        }
        &-button-wrapper {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
    .detail-modal-body{
        padding: 40px 40px 0 40px;
        flex-grow: 1;
        position: relative;
    }
    .detail-modal-footer {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        gap: 12px;
        padding: 40px;
        border-top: 1px solid variables.$modal-border-color;
        color: variables.$modal-footer-color;
        font-weight: 300;
    }
    .active-page-icon{
        color: variables.$primary-color;
        font-size: 30px;
        margin-right: 14px;
        transform: translateY(-1px);
    }
    
    .content {
        .row-pagination{
            margin: 0 !important;
        }
        .emptystate-wrap{
            margin-top: 0 !important;
            .empty-state{
                font-weight: 300;
                font-size: 14px;
                padding-top: 20px;
            }
        }
    }
    
    // side panel
    .side-panel-wrap{
        padding: 24px;
        width: variables.$sidePanelWidth;
        height: calc(100vh - #{variables.$headerHeight});
        overflow-y: auto;
        position: fixed;
        top: variables.$headerHeight;
        z-index: 2;
    }
    .side-panel{
        border: 1px solid #edecec;
        width: variables.$sidePanelWidth;
    
        width: 100%;
    
        height: auto;
        padding: 16px 16px 32px 16px;
        border-radius: 8px;
        border: none;
        background: rgba(57, 70, 86, 0.03);
        background: rgba(57, 70, 86, 0.04);
        background: rgba(57, 70, 86, 0.05);
        .search-title{
            margin-bottom: 24px;
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
            color: variables.$text-color;
        }
        .side-panel-footer{
            padding-top: variables.$spacing-s;
            font-size: variables.$fontsize-xxs;
            text-align: center;
            color: #555555;
            justify-self: end;
            width: 100%;
            a{
                color: variables.$secondary-color;
                text-decoration: underline;
            }
        }
        .field-date input{
            width: 100%;
        }
    }
    .side-panel-main{
        margin-left: variables.$sidePanelWidth;
        max-width: calc(100vw - #{variables.$sidePanelWidth});
        overflow: auto;
        z-index: 2;
    }
    .panel-footer{
        background: #ebebeb;
        padding-top: 28px;
        padding-bottom: 28px;
    }

    .info-foot{
        display: flex;
        flex-direction: column;
        &>p{
            color: #717277;
            margin-bottom: 8px;
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
}

// small screens
@media only screen and (max-width: 1600px) {
    .modal-wrap{
        .panel-head{
            padding-left: 32px !important;
            padding-right: 32px !important;
            button, .back-btn{
                margin-bottom: 32px !important;
            }
            .titles{
                .title{
                    // font-size: 22px;
                    // line-height: 30px;
                    // margin-bottom: 2px;
                }
            }
        }
    }
}