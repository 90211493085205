$grid-gutter-width: 32px;
@use "./assets/scss/reset.scss";
@use 'bootstrap/scss/bootstrap';
@use 'ngx-toastr/toastr';
@use 'quill/dist/quill.snow.css';
@use "./assets/scss/variables.scss";
@use "./assets/scss/helpers.scss";
@use "./assets/scss/animations.scss";
@use "./assets/scss/tooltips.scss";
@use "./assets/scss/ie.scss";
@use "./assets/scss/icons.scss";
@use "./assets/scss/fonts.scss";
@use "./assets/scss/richtext.scss";
@use "./assets/scss/tables.scss";
@use "./assets/scss/fields.scss";
@use "./assets/scss/buttons.scss";
@use "./assets/scss/top-bar.scss";
@use "./assets/scss/modals.scss";
@use "./assets/scss/popovers.scss";
@use "./assets/scss/detail.scss";
@use "./assets/scss/custom.scss";

// general 
::placeholder {
    color: #ACB5C1;
    opacity: 1;
}
[contenteditable] {
    outline: 0px solid transparent;
}
[contenteditable]:empty::before{
    content:attr(data-placeholder);
    color: #ACB5C1;
}
body {
    font-size: variables.$fontsize-s;
    line-height: 1;
    background: #fff;
}
.background{
    background: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}
._version{
    position: fixed;
    top: 6px;
    left: 8px;
    z-index: 999999;
    font-size: 10px;
    text-transform: uppercase;
    color: #fff;
    background-color: #999999;
    padding: 5px 7px;
    padding-top: 6px;
    border-radius: 4px;
    opacity: 0.2;
    // opacity: 1;
    transition: 0.1s ease;
    padding-left: 23px;
    background-image: url(/assets/img/icons/_version.svg);
    background-size: 13px;
    background-position: center left 6px;
    background-repeat: no-repeat;
    cursor: pointer;
    &:hover{
        opacity: 1;
    }
}
.modal-open--route{
    overflow: hidden;
}
body * {
    font-family: 'Roboto', sans-serif;
}
a{
    // word-break: break-word;
}
h1, h2, h3, h4{
    margin: 0;
    color: variables.$text-color;
}
h1, h1>span{
    font-size: 32px;
    font-weight: 500;
}
h2{
    font-size: 26px;
}
h3{
    font-size: 20px;
}
h4{
    font-size: variables.$fontsize-l;
}
.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
body div{
    ul, ol{
        margin-bottom: 0;
        padding-left: 0;
    }
    ul li {color: variables.$text-color;}
    ol li {color: variables.$text-color;}
}
.scroll-y{
    overflow-y: auto;
}
.scroll-x{
    overflow-x: auto;
}
.subtitle-m{
    color: #acacac;
    font-size: variables.$fontsize-l;
}
main{
    min-height: 100vh;
    color: variables.$text-color;
    background: #fff;
    position: relative;
    .busy-overlay{
        cursor: progress;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999999999;
    }
}
.busy{
    opacity: .5;
    pointer-events: none;
}
.container {
    padding: 0;
    position: relative;
    margin: unset;
}

.page{
    padding: 32px 40px 40px 40px;
}

.descr{
    // color: variables.$gray;
    // font-weight: 300;
}

// helpers
.saving{
    opacity: .7;
    pointer-events: none;
}
.saving--pre{
    transition: .1s ease;
}
.no-pointer{
    pointer-events: none;
}
.opacity-hide{
    transition: .1s ease;
    opacity: 0;
    pointer-events: none;
}
.opacity-show{
    opacity: 1 !important;
    pointer-events: all !important;
}
.lh-1{
    line-height: 1;
}
.h-100{
    height: 100%;
}
.uppercase{
    text-transform: uppercase;
}

// toasts
#toast-container{
    .ngx-toastr{
        box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.2) !important;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 53px;
        padding-right: 30px;
        border-radius: 4px;
        background: #fff;
        margin: 0 0 11px;
        width: 400px;
        .toast-close-button{
            background-image: url(/assets/img/icons/x.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 10px;
            height: 10px;
            transition: .1s ease;
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translatey(-50%);
            &:hover{
                opacity: .7;
            }
            span{
                display: none;
            }
        }
    }
    .toast-success{
        border-left: 5px solid #5fbb97;
    }
    .toast-warning{
        border-left: 5px solid #ef8a17;
    }
    .toast-error{
        border-left: 5px solid variables.$error-red;
    }
    .toast-info{
        border-left: 5px solid variables.$secondary-color;
    }
    .toast-bottom-right {
        right: 16px;
        bottom: 0px;
    }
    .toast-title{
        font-size: variables.$fontsize-m;
        color: variables.$text-color;
    }
    .toast-message{
        font-size: variables.$fontsize-xs;
        color: #555555;
        margin-top: 5px;
    }
    .toast-warning{
        background-image: url(/assets/img/icons/icon-toast-warning.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-error{
        background-image: url(/assets/img/icons/icon-toast-error.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-info{
        background-image: url(/assets/img/icons/icon-toast-info.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-success{
        background-image: url(/assets/img/icons/icon-toast-success.svg);
        background-repeat: no-repeat;
        background-size: 21px 21px;
        background-position: center left 15px;
    }
    
}
// display
.display-group{
    .sep{
         &:last-of-type{
             display: none;
         }
         &:first-of-type{
             display: none;
         }
    }
}
.display-label{
    font-size: variables.$fontsize-s;
    line-height: 20px;
    margin-bottom: 6px;
    color: variables.$primary-color;
    font-weight: 500;
    &.uppercase{
        font-size: variables.$fontsize-xxs;
        font-weight: 500;
    }
}
.display-row{
    &>div{
        margin-bottom: 32px;
    }
    &.display-row--form{
        &>div{
            margin-bottom: 24px;
            // margin-bottom: 32px;
        }
    }
    .display-value{
        // margin-bottom: variables.$spacing-xs;
        color: variables.$text-color;
        font-size: variables.$fontsize-display;
        line-height: 24px;
        &:last-of-type{
            // margin-bottom: 0;
        }
    }

    .display-content > :nth-child(n+3) {
        margin-top: 8px;
        padding-top: 8px;
        border-top: 1px solid variables.$modal-input-border;
    }

    &:last-of-type{
        // margin-bottom: 0;
    }
    .display-content{
        // margin-bottom: variables.$spacing-m;
        &:last-of-type{
            // margin-bottom: 0;
        }
    }
}

// lists
ul.app-list {
     width: 100%;
    display: inline-block;
	&.app-list--table{
        li:first-of-type{
            padding-top: 0 !important;
        }
        li:last-of-type{
            border-bottom: none !important;
            padding-bottom: 0 !important;
        }
    }
	&.app-list--single{
        li:last-of-type{
            border-bottom: none !important;
            padding-bottom: 0 !important;
        }
    }
	&.app-list--field{
        margin-bottom: 0;
        margin-top: -5px;
        li:last-of-type{
            border-bottom: none !important;
            // padding-bottom: 0 !important;
        }
    }
	li {
        border-bottom: 1px solid #E6E6E6;
        padding-top: 8px;
        padding-bottom: 8px;
        color: variables.$text-color;
        line-height: 24px;
        font-size: variables.$fontsize-display;
        position: relative;
        padding-right: 16px;
        .delete-icon{
            position: absolute;
            right: 0;
            top: 15px;
            &.disabled {
                color: variables.$gray;
                cursor: not-allowed;
            }
        }
        .subProduct{
            display: block !important;
            margin: 0 !important;
            border: 0 !important;
            padding: 0 !important;
            margin-top: 1px !important;
            font-size: 12px !important;
            line-height: 18px !important;
            color: #787878 !important;
        }
        &::before {
            display: none;
        }
        .comment{
            margin: 0;
            border: 0;
            padding: 0;
            margin-top: 1px;
            font-size: 12px;
            line-height: 18px;
            color: #787878;
        }
	}
}
.display-value{
    ul.app-list{
        margin-top: -8px;
    }
}
.delete-icon {
    font-size: 12px;
    cursor: pointer;
    color: variables.$delete-red;
}

.ql-editor {
    overflow: hidden;
}

// stamps
.has-stamps{
    padding-right: 12px;
}
.stamps{
    display: inline-block;
}
.stamp{
    margin-right: 8px;
    display: inline-block;
    border-radius: 3px;
    padding: 0px 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    &:last-of-type{
        margin-right: 0;
    }
    &.stamp--primary{
        color: variables.$primary-color;
        background-color: variables.$primary-color-lightest;
    }
    &.stamp--error{
        color: variables.$error-red-alt;
        background-color: #ffedee;
    }
    &.stamp--passive{
        color: #909090;
        background-color: #E2E3E6;
    }
}

// captcha
.grecaptcha-badge {
    opacity: 0;
    pointer-events: none;
    transition: right 0.3s ease 0s, opacity .2s ease 0s !important;
    bottom: 100px !important;
}
.show-captcha .grecaptcha-badge {
    opacity: 1;
    pointer-events: all;
}